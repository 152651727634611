import * as React from 'react';
import { graphql } from 'gatsby';
import { FixedObject } from 'gatsby-image';
import { Document } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Language } from '../data/languages';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { models } from '../../models';

type Props = {
  data: {
    contentfulAbout: {
      title: string;
      name: string;
      description: { json: Document };
      email: string;
      node_locale: Language;
      seoText: { seoText: string };
      featuredImage: { fixed: FixedObject };
    };
  };
  location: Location;
};

function About({
  data: {
    contentfulAbout: {
      seoText,
      featuredImage,
      title,
      name,
      description,
      email,
      node_locale,
    },
  },
  location,
}: Props) {
  return (
    <Layout
      lang={node_locale}
      location={location}
      pathnameWithoutLang={models.dynamic.about.path}
    >
      <SEO
        featuredImage={featuredImage?.fixed.src}
        title={title}
        description={seoText.seoText}
        lang={node_locale}
      />
      <div className="band">
        <div className="wrapper">
          <div className="container container--small">
            <div className="layout layout--flush">
              <article className="layout__item">
                <h1 className="about__name">{name}</h1>
                <div>{documentToReactComponents(description.json)}</div>
                <span>
                  Contact: <a href={`mailto:${email}`}>{email}</a>
                </span>
              </article>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default About;

export const pageQuery = graphql`
  query aboutQuery($id: String!) {
    contentfulAbout(id: { eq: $id }) {
      title
      name
      seoText {
        seoText
      }
      featuredImage {
        fixed(width: 1200) {
          src
          srcSet
        }
      }
      description {
        json
      }
      email
      node_locale
    }
  }
`;
